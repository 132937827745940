import { gql } from 'graphql-request';

export const getAssignedProductsDashboardQuery = gql`
  query getAssignedProductsDashboard($billingCustomerId: String!) {
    getAssignedProducts(billingCustomerId: $billingCustomerId) {
      benefitInfo {
        beneficialBrand
        selectedSafeOnlineBenefit
        selectedTVBenefit
      }
      groupLevelProduct {
        id
        name
        type
        contractStartDate
        contractPeriod
        contractEndDate
      }
      internetProducts {
        id
        internetSecurity {
          id
          licenseAmount
          name
          serviceType
          type
        }
        name
        downloadSpeed
        uploadSpeed
      }
      telephonyProducts {
        id
        name
        extraServices {
          type
        }
      }
      televisionProducts {
        id
        name
        packages {
          benefitBrand
          source
          type
          name
          isK2vOnly
          subscription {
            accountID
            partnerID
            tier
            price
            priceExclVAT
            chargeMethod
            purchaseDate
            endDate
            renewalDate
            status
            channel
            OTTstatus
            OTTactivationDate
            pricepointIdentifier
            regularPrice
            pricepointDescription
            subscriptionID
          }
        }
      }
      hasDigitalTV
    }
  }
`;
export const getAssignedProductsOverviewQuery = gql`
  query getAssignedProductsOverview($billingCustomerId: String!) {
    getAssignedProducts(billingCustomerId: $billingCustomerId) {
      benefitInfo {
        beneficialBrand
        selectedSafeOnlineBenefit
        selectedTVBenefit
      }
      groupLevelProduct {
        contractStartDate
        contractPeriod
        id
        name
        type
        contractEndDate
      }
      internetProducts {
        id
        name
        type
        downloadSpeed
        uploadSpeed
      }
      telephonyProducts {
        id
        name
        type
      }
      televisionProducts {
        id
        name
        type
        packages {
          benefitBrand
          source
          type
          name
          title
          isK2vOnly
          subscription {
            accountID
            partnerID
            tier
            price
            priceExclVAT
            chargeMethod
            purchaseDate
            endDate
            renewalDate
            status
            channel
            OTTstatus
            OTTactivationDate
            pricepointIdentifier
            regularPrice
            pricepointDescription
            subscriptionID
          }
        }
        offering {
          id
          name
        }
      }
    }
  }
`;

export const getFullTvProductsQuery = gql`
  query getFullTvProducts($billingCustomerId: String!) {
    getAssignedProducts(billingCustomerId: $billingCustomerId) {
      televisionProducts {
        id
        name
        packages {
          benefitBrand
          source
          type
          name
          title
          isK2vOnly
          subscription {
            accountID
            partnerID
            tier
            price
            priceExclVAT
            chargeMethod
            purchaseDate
            endDate
            renewalDate
            status
            channel
            OTTstatus
            OTTactivationDate
            pricepointIdentifier
            regularPrice
            pricepointDescription
            subscriptionID
          }
        }
        receivers {
          activationCode
          connectCloudCapable
          cpeType
          deviceModel
          id
          name
          purchaseCategory
          serialNumber
          smartcardNumber
          visualId
        }
        type
      }
    }
  }
`;
export const getFullInternetProductsQuery = gql`
  query getFullInternetProducts($billingCustomerId: String!) {
    getAssignedProducts(billingCustomerId: $billingCustomerId) {
      internetProducts {
        downloadSpeed
        id
        internetSecurity {
          id
          licenseAmount
          name
          serviceType
          type
        }
        mediabox {
          activationCode
          connectCloudCapable
          cpeType
          deviceModel
          id
          name
          purchaseCategory
          serialNumber
          smartcardNumber
          visualId
        }
        modem {
          brand
          connectCloudCapable
          model
          serialNumber
          visualId
          colour
          logicalEquipmentId
        }
        name
        type
        uploadSpeed
        wifiDevices {
          connectCloudCapable
          purchaseCategory
          serialNumber
          type
          name
          visualId
        }
      }
    }
  }
`;
export const getFullTelephonyProductsQuery = gql`
  query getFullTelephonyProducts($billingCustomerId: String!) {
    getAssignedProducts(billingCustomerId: $billingCustomerId) {
      telephonyProducts {
        extraServices {
          name
          type
        }
        id
        name
        telephoneNumber
        type
      }
    }
  }
`;

export const resetPincodeQuery = gql`
  mutation resetPincode($billingCustomerId: String!, $assignedProductId: String!) {
    resetPincode(billingCustomerId: $billingCustomerId, assignedProductId: $assignedProductId) {
      success
    }
  }
`;

export const refreshEntitlementsQuery = gql`
  mutation refreshEntitlements($billingCustomerId: String!, $productId: String!, $subProductId: String!) {
    refreshEntitlements(billingCustomerId: $billingCustomerId, productId: $productId, subProductId: $subProductId) {
      success
    }
  }
`;

import {
  StreamingService,
  UnbilledEvent,
  UnbilledTelephonyUsage,
  UnbilledTelevisionEvent,
  UnbilledTelevisionUsage,
  UnbilledUsage,
  UnbilledUsageApi,
} from 'api';
import { BillingCustomerContext } from 'context/billing-customer.context';
import { useFeature } from 'context/feature/feature.context';
import { useProductContext } from 'context/product/product.context';
import { FEATURES } from 'models/features.model';
import { ISubscriptionValues } from 'pages/Usage/usage.models';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useState } from 'react';
import transformers from 'utils/transformers';

export interface IUsageContext {
  isLoading: boolean;
  hasError: boolean;
  unbilledTelephonyUsage: UnbilledTelephonyUsage[];
  unbilledTelevisionUsage: UnbilledTelevisionUsage[];
  unbilledStreamingUsage: UnbilledUsage[];
  televisionEvents: UnbilledTelevisionEvent[] | null;
  streamingEvents: UnbilledEvent[] | null;
  phoneNumbers: ISubscriptionValues[] | null;
  televisionEventsChargeAmount: number;
  streamingEventsChargeAmount: number;
  phoneEventsChargeAmount: number;
  totalChargeAmount: number;
  getUsage: () => Promise<void>;
  enabledStreamingServices: Record<StreamingService, boolean>;
}

interface UsageProviderProps {
  children: JSX.Element;
}

const UsageContext = createContext<IUsageContext | undefined>(undefined);

const useUsageContext = () => useContext(UsageContext) as IUsageContext;

const UsageProvider = ({ children }: UsageProviderProps) => {
  const billingCustomerContext = useContext(BillingCustomerContext);
  const { products, hasTv, hasPhone } = useProductContext();
  const isStreamingEnabled = useFeature(FEATURES.STREAMING_USAGE);
  const isStreamingSkyShowtimeEnabled = useFeature(FEATURES.STREAMING_SKYSHOWTIME_ENABLED);
  const isStreamingVideolandEnabled = useFeature(FEATURES.STREAMING_VIDEOLAND_ENABLED);
  const isStreamingInTVPackages = useFeature(FEATURES.STREAMING_IN_TV_PACKAGES);
  const [isLoading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const [unbilledTelephonyUsage, setUnbilledTelephoneUsage] = useState<UnbilledTelephonyUsage[]>([]);
  const [unbilledTelevisionUsage, setUnbilledTelevisionUsage] = useState<UnbilledTelevisionUsage[]>([]);
  const [unbilledStreamingUsage, setUnbilledStreamingUsage] = useState<UnbilledUsage[]>([]);
  const [streamingEvents, setStreamingEvents] = useState<UnbilledEvent[] | null>(null);
  const [televisionEvents, setTelevisionEvents] = useState<UnbilledTelevisionEvent[] | null>(null);
  const [phoneNumbers, setPhoneNumbers] = useState<ISubscriptionValues[] | null>(null);
  const [televisionEventsChargeAmount, setTelevisionEventsChargeAmount] = useState<number>(0);
  const [phoneEventsChargeAmount, setPhoneEventsChargeAmount] = useState<number>(0);
  const [totalChargeAmount, setTotalChargeAmount] = useState<number>(0);
  const [streamingEventsChargeAmount, setStreamingEventsChargeAmount] = useState<number>(0);

  const getTelephonySummary = async (activeBcId: string) => {
    const { data: unbilledTelephonyUsage } = await UnbilledUsageApi.getUnbilledTelephonyUsage(activeBcId);
    const phoneNumbers = R.compose(
      R.map(
        R.applySpec<ISubscriptionValues>({
          value: R.prop('id'),
          label: ({ phoneNumber }: UnbilledTelephonyUsage) => transformers.transformMsisdnToReadable(phoneNumber),
        })
      ),
      R.defaultTo([])
    )(unbilledTelephonyUsage);
    setPhoneNumbers(phoneNumbers);
    setUnbilledTelephoneUsage(unbilledTelephonyUsage || []);
  };

  const getTelevisionEvents = async (activeBcId: string, activeProductId: string) => {
    const { data } = await UnbilledUsageApi.getUnbilledTelevisionEvents(activeBcId, activeProductId);
    setTelevisionEvents(data);
  };

  const getTelevisionUsage = async (activeBcId: string) => {
    const { data } = await UnbilledUsageApi.getUnbilledTelevisionUsage(activeBcId);
    setUnbilledTelevisionUsage(data);
  };

  const enabledStreamingServices = {
    [StreamingService.SKY_SHOWTIME]: isStreamingSkyShowtimeEnabled,
    [StreamingService.SKYSHOWTIME]: isStreamingSkyShowtimeEnabled && isStreamingInTVPackages,
    [StreamingService.VIDEOLAND]: isStreamingVideolandEnabled,
    [StreamingService.VIDEOLAND_BASIS]: isStreamingVideolandEnabled && isStreamingInTVPackages,
    [StreamingService.VIDEOLAND_PLUS]: isStreamingVideolandEnabled && isStreamingInTVPackages,
    [StreamingService.VIDEOLAND_PREMIUM]: isStreamingVideolandEnabled && isStreamingInTVPackages,
    [StreamingService.NETFLIX]: true,
    [StreamingService.NETFLIX_BASIC]: isStreamingInTVPackages,
    [StreamingService.NETFLIX_STANDAARD]: isStreamingInTVPackages,
    [StreamingService.NETFLIX_PREMIUM]: isStreamingInTVPackages,
    [StreamingService.DISNEY_PLUS]: true,
    [StreamingService.DISNEY_PLUS_STANDAARD_MET_RECLAME]: true,
    [StreamingService.VIAPLAY]: true,
    [StreamingService.HBO_MAX]: true,
    [StreamingService.HBO_MAX_STANDAARD]: true,
  };

  const getStreamingUsage = async (activeBcId: string) => {
    const { data } = await UnbilledUsageApi.getUnbilledStreamingUsage(activeBcId);

    setUnbilledStreamingUsage(data.filter((streaming) => enabledStreamingServices[streaming.type]));
  };

  const getStreamingEvents = async (activeBcId: string) => {
    const { data } = await UnbilledUsageApi.getUnbilledStreamingEvents(activeBcId);

    setStreamingEvents(data.filter((event) => enabledStreamingServices[event.chargeCategory]));
  };

  const calculateChargeAmounts = (
    unbilledTelevisionUsage: UnbilledTelevisionUsage[],
    telephonySummary: UnbilledTelephonyUsage[],
    unbilledStreamingUsage: UnbilledUsage[]
  ) => {
    const televisionEventsChargeAmount = R.compose(
      R.sum,
      R.map(R.pathOr(0, ['price', 'includingVat']))
    )(unbilledTelevisionUsage);

    const phoneEventsChargeAmount = R.compose(
      R.sum,
      R.map(R.pathOr(0, ['price', 'includingVat'])),
      R.flatten,
      R.map(R.propOr([], 'telephonyUsageCategories'))
    )(telephonySummary);

    const streamingEventsChargeAmount = R.compose(
      R.sum,
      R.map(R.pathOr(0, ['price', 'includingVat']))
    )(unbilledStreamingUsage);

    const totalChargeAmount = televisionEventsChargeAmount + phoneEventsChargeAmount + streamingEventsChargeAmount;

    setTelevisionEventsChargeAmount(televisionEventsChargeAmount);
    setPhoneEventsChargeAmount(phoneEventsChargeAmount);
    setStreamingEventsChargeAmount(streamingEventsChargeAmount);
    setTotalChargeAmount(totalChargeAmount);
  };

  const getUsage = async () => {
    try {
      const { activeBcId } = billingCustomerContext;
      const [activeTelevisionProduct] = products?.televisionProducts || [];
      const activeProductId = activeTelevisionProduct && activeTelevisionProduct?.id;

      await Promise.all([
        hasPhone() ? getTelephonySummary(activeBcId) : Promise.resolve([]),
        hasTv() ? getTelevisionUsage(activeBcId) : Promise.resolve([]),
        activeProductId ? getTelevisionEvents(activeBcId, activeProductId) : Promise.resolve([]),
        isStreamingEnabled ? getStreamingUsage(activeBcId) : Promise.resolve([]),
        isStreamingEnabled ? getStreamingEvents(activeBcId) : Promise.resolve([]),
      ]);
      setLoading(false);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    calculateChargeAmounts(unbilledTelevisionUsage, unbilledTelephonyUsage, unbilledStreamingUsage);
  }, [unbilledTelephonyUsage, unbilledTelevisionUsage, unbilledStreamingUsage]);

  return (
    <UsageContext.Provider
      value={{
        isLoading,
        hasError,
        unbilledTelephonyUsage,
        unbilledTelevisionUsage,
        unbilledStreamingUsage,
        televisionEvents,
        streamingEvents,
        televisionEventsChargeAmount,
        streamingEventsChargeAmount,
        phoneEventsChargeAmount,
        totalChargeAmount,
        phoneNumbers,
        getUsage,
        enabledStreamingServices,
      }}>
      {children}
    </UsageContext.Provider>
  );
};

export { UsageContext, UsageProvider, useUsageContext };

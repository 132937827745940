import { OidcService } from '@hawaii-framework/oidc-implicit-core';
import { IApi } from 'api/api-factory';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { DEFAULT_OIDC_SCOPES, GetOidcConfig } from 'config/app.config';
import { appSettings } from 'config/app.settings';
import get from 'lodash.get';

const STATIC_BASE_PATH = `${appSettings.ENV_PUBLIC_URL}static/`;
const KAHUNA_ASSETS_BASE_PATH = '/_assets/';

interface GraphQlError {
  message: string;
  path: string[];
  extensions: {
    code: string;
    exception: {
      message: string;
      response: {
        statusCode: number;
        message: string;
        error: string;
        code?: string;
      };
    };
  };
}

const getGraphQlErrorMessage = (response: AxiosResponse) => {
  const errors: GraphQlError[] = response.data?.errors;

  const [error] = errors;
  if (error?.extensions?.code || error?.extensions?.exception?.response?.code) {
    return {
      code: error?.extensions?.code || error.extensions.exception.response.code,
      message: error.message,
      data: response.data?.data,
    };
  }
  // @ts-expect-error TS(2358): The left-hand side of an 'instanceof' expression m... Remove this comment to see the full error message
  if (error?.extensions?.exception?.response?.message instanceof Array) {
    return {
      code: error.extensions.exception.response.statusCode,
      error: error.extensions.exception.response.error,
      message: error.extensions.exception.response.message,
      data: response.data?.data,
    };
  }
  if (error) {
    return { message: error.message };
  }

  return null;
};

type Headers = { [key: string]: string };

class CommonAPI implements IApi {
  private isRefreshingToken = false;

  public headers: Headers = {};

  public apiInst: AxiosInstance = axios.create({
    headers: this.headers,
    baseURL: appSettings.ENV_REST_BASE_URL_SELFSERVICE,
  });

  public idmInst: AxiosInstance = axios.create({
    headers: this.headers,
    baseURL: appSettings.ENV_REST_BASE_URL_IDM,
  });

  public staticInst: AxiosInstance = axios.create({
    baseURL: appSettings.ENV_REST_BASE_URL_SELFSERVICE + STATIC_BASE_PATH,
  });

  public feConfigsInst: AxiosInstance = axios.create({
    baseURL: appSettings.ENV_FEATURE_CONFIG_BASE_URL,
  });

  public kahunaAssetsInst: AxiosInstance = axios.create({
    baseURL: appSettings.ENV_VODAFONE_URL + KAHUNA_ASSETS_BASE_PATH,
  });

  public graphQLInst: AxiosInstance = axios.create({
    headers: this.headers,
    baseURL: appSettings.ENV_GRAPHQL_BASE_URL_SELFSERVICE,
  });

  public mbssApiInst: AxiosInstance = axios.create({
    headers: this.headers,
    baseURL: appSettings.ENV_MBSS_REST_BASE_URL,
  });

  public kvkApiInst: AxiosInstance = axios.create({
    headers: {
      ...this.headers,
      'Content-Type': 'application/json',
    },
    baseURL: (appSettings as any).ENV_KVK_GRAPHQL_BASE_URL,
  });

  constructor() {
    this.idmInst.interceptors.response.use((response: AxiosResponse) => {
      if (response.data?.statusCode === 401) {
        window.location.href = GetOidcConfig().silent_logout_uri as string;
      }
      return response;
    });
  }

  /**
   * Requests GET call from my/statics endpoint
   * @param url relative base_url to static folder
   */
  public getStatic(url: string): Promise<any> {
    return this.static({
      url,
    });
  }

  /**
   * API Method simply calls the Axios api, but transforms the backend status codes
   * (inside the data) to correct thens / catches.
   * @param options
   */
  public async api(options: AxiosRequestConfig): Promise<any> {
    await this.checkIfTokenExpiresAndRefreshWhenNeeded();

    return new Promise((resolve, reject) => {
      this.apiInst(options)
        .then((response) => {
          resolve({
            data: response.data,
          });
        })
        .catch((error) => {
          reject({
            error: error && error.response,
          });
        });
    });
  }

  /**
   * Calls the Axios API and transforms output according to how the SelfService API model does so for consistency.
   */
  public async getFromGraphQL(options: AxiosRequestConfig, authenticated = true): Promise<any> {
    if (authenticated) {
      await this.checkIfTokenExpiresAndRefreshWhenNeeded();
    }

    return new Promise((resolve, reject) => {
      this.graphQLInst({
        ...options,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Agent-id': get(options, ['headers', 'agentId'], ''),
          'Correlation-Id': crypto.randomUUID(),
          ...options.headers,
        },
      })
        .then((response) => {
          if (response.data?.errors) {
            reject(getGraphQlErrorMessage(response));
          } else {
            resolve(response.data.data);
          }
        })
        .catch((error) => {
          reject({
            error: error && error.response,
          });
        });
    });
  }

  public async getFromRest(options: AxiosRequestConfig, authenticated = true): Promise<any> {
    if (authenticated) {
      await this.checkIfTokenExpiresAndRefreshWhenNeeded();
    }

    return new Promise((resolve, reject) => {
      this.mbssApiInst(options)
        .then((response) => {
          resolve({ data: response.data });
        })
        .catch((error) => {
          reject({ error: error && error.response });
        });
    });
  }

  public idm(options: AxiosRequestConfig) {
    this.checkIfTokenExpiresAndRefreshWhenNeeded();

    return new Promise((resolve, reject) => {
      this.idmInst(options)
        .then((response) => {
          resolve({
            data: response.data,
          });
        })
        .catch((error) => {
          reject({
            error: error && error.response,
          });
        });
    });
  }

  public kahunaAssets(options: AxiosRequestConfig) {
    return new Promise((resolve, reject) => {
      this.kahunaAssetsInst(options)
        .then((response) => {
          if (response.status < 400) {
            resolve({
              data: response.data,
              status: response.status,
            });
          } else {
            reject({
              data: response.data,
              status: response.status,
              error: response,
            });
          }
        })
        .catch((error) => {
          reject({
            error,
          });
        });
    });
  }

  public static(options: AxiosRequestConfig) {
    return new Promise((resolve, reject) => {
      this.staticInst(options)
        .then((response) => {
          if (response.status < 400) {
            resolve({
              data: response.data,
              status: response.status,
            });
          } else {
            reject({
              data: response.data,
              status: response.status,
              error: response,
            });
          }
        })
        .catch((error) => {
          reject({
            error,
          });
        });
    });
  }

  getFromFeConfigs(url: string): Promise<any> {
    return this.feConfigsInst({ url, method: 'get' });
  }

  /**
   * Requests GET call from my/rest endpoint
   * @param url relative base_url
   * @param data optional payload
   * @param params optional axios request config params
   */
  public getAuth(url: string, data?: any, params?: any): Promise<any> {
    return this.idm({
      ...params,
      url,
      data,
      method: 'get',
    });
  }

  /**
   * Requests GET call from /_assets endpoint
   * @param url relative url to _assets folder
   */
  public getFromKahunaAssets(url: string): Promise<any> {
    return this.kahunaAssets({
      url,
    });
  }

  public setHeader(key: string, value: string) {
    this.apiInst.defaults.headers.common[key] = value;
    this.graphQLInst.defaults.headers.common[key] = value;
    this.idmInst.defaults.headers.common[key] = value;
    this.mbssApiInst.defaults.headers.common[key] = value;
  }

  public async checkIfTokenExpiresAndRefreshWhenNeeded() {
    // Check if the token expires in the next (x) seconds,
    // if so, set trigger a silent refresh of the Access Token in the OIDC Service.
    // The try catch is only here to prevent errors thrown in jest tests
    try {
      const token = OidcService.getStoredToken({ scopes: DEFAULT_OIDC_SCOPES });

      if (token) {
        await OidcService.checkIfTokenExpiresAndRefreshWhenNeeded(token, {
          almostExpiredThreshold: 300,
        })
          .then(() => {
            const newToken = OidcService.getStoredToken({ scopes: DEFAULT_OIDC_SCOPES });
            if (newToken) {
              this.setHeader('Authorization', OidcService.getAuthHeader(newToken));
            }
          })
          .catch(async () => {
            this.setHeader('Authorization', '');
            await this.refreshToken();
          });
      } else {
        this.setHeader('Authorization', '');
        await this.refreshToken();
      }
    } catch (e) {
      this.setHeader('Authorization', '');
      await this.refreshToken();
    }
  }

  async refreshToken() {
    if (this.isRefreshingToken) return;
    this.isRefreshingToken = true;

    const result = await OidcService.silentRefreshAccessToken({ scopes: DEFAULT_OIDC_SCOPES });

    if (result) {
      const newToken = OidcService.getStoredToken({ scopes: DEFAULT_OIDC_SCOPES });
      if (newToken) {
        this.setHeader('Authorization', OidcService.getAuthHeader(newToken));
      }
    } else {
      await OidcService.checkSession();
    }
    this.isRefreshingToken = false;
  }

  public async searchKvkByAddress(
    street?: string,
    postcode?: string,
    houseNumber?: number,
    houseNumberAddition?: string
  ): Promise<{ trade_name: string }[] | undefined> {
    try {
      const { data }: { data: { data: { searchDutchBusiness: { results: { item: [{ trade_name: string }] } } } } } =
        await this.kvkApiInst({
          method: 'post',
          data: {
            query: `query ($street: String, $postcode: String, $houseNumber: Int, $houseNumberAddition: String) {
            searchDutchBusiness(street: $street, postcode: $postcode, house_number: $houseNumber, house_number_addition: $houseNumberAddition, strict_search: true) {
              results {
                item {
                  trade_name
                }
              }
            }
          }`,
            variables: { street, postcode: postcode?.replace(' ', ''), houseNumber, houseNumberAddition },
          },
        });

      return data?.data?.searchDutchBusiness?.results?.item;
    } catch (_error) {
      return undefined;
    }
  }
}

export default {
  apiV1: new CommonAPI(),
};
